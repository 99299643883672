import {
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache,
} from '@apollo/client';
import fetch from 'isomorphic-fetch';

const httpLink = createHttpLink({
  uri: `${process.env.REACT_APP_SERVER_URL}/public`,
  fetch,
});
const link = ApolloLink.from([httpLink]);

export const client = new ApolloClient({
  link,
  cache: new InMemoryCache(),
});
export const wrapRootElement = ({ element }) => {};
